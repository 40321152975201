import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import '../stylesheets/pages/index.scss';
import OpeningAnimation from '../components/layout/opening';
import { ShapeImages, Logos, CaseStudies, Testimonials } from '../components/slices';

class IndexPage extends Component {

  constructor(props) {

    super(props);

    this.state = {
      showOpeningAnimation: true,
      windowWidth: '1200',
    };

    this.resizeWindow = this.resizeWindow.bind(this);
  }

  componentDidMount() {

    if (typeof window !== `undefined`) {

      let showOpening = window.sessionStorage.getItem('showOpening');

      this.setState({
        showOpeningAnimation: showOpening == null ? true : false,
        windowWidth: window.innerWidth,
      });

      this.resizeWindow();
      window.addEventListener('resize', this.resizeWindow);
    }

  }

  resizeWindow() {
    if (typeof window !== `undefined`) {

      this.setState({
        windowWidth: window.innerWidth,
      });
    }

  }

  render() {

    const { showOpeningAnimation } = this.state;
    const data = this.props.data.homepage.edges[0].node.data;
    const caseStudies = this.props.data.casestudies.edges;
    const header = this.props.data.header.data;
    const footer = this.props.data.footer.data;


    const content = data.body.map((slice, index) => {
      switch (slice.slice_type) {
        case 'shape_images':
          return <ShapeImages key={index} sliceData={slice} />;
        case 'logos':
          return <Logos key={index} sliceData={slice} />;
        case 'case_studies':
          return <CaseStudies key={index} sliceData={slice} caseStudies={caseStudies} />
        case 'testimonials':
          return <Testimonials key={index} sliceData={slice} />
        default:
          return null;
      };
    });

    const windowWidth = this.state.windowWidth;

    return (
      windowWidth > 767 ?
        <>
          <Layout headerStyle="split" title={data.meta_title.text} description={data.meta_description.text} showOpeningAnimation={showOpeningAnimation} header={header} footer={footer}>
            <OpeningAnimation showOpeningAnimation={showOpeningAnimation}>
              <div className="homepage">
                <div className="left-column col-6 text-start">
                  <h1>{data.left_heading.text}</h1>
                  <h5>{data.left_text.text}</h5>
                </div>
                <div className="right-column col-6 text-end">
                  <h1>{data.right_heading.text}</h1>
                  <h5>{data.right_text.text}</h5>
                </div>
                <div className="second-row w-100 text-center">
                  <h5 className="w-100 center-text me-1">{data.center_text.text}</h5>
                </div>
              </div>
            </OpeningAnimation>
            {content}
          </Layout>
        </> :
        <>
          <Layout headerStyle="light" title={data.meta_title.text} description={data.meta_description.text} header={header} footer={footer}>
            <div className="homepage-mobile d-flex flex-wrap align-items-center w-100">
              <div className="w-100">
                <div className="left-column text-start">
                  <div className="container d-flex flex-wrap ps-4">
                    <h1>{data.left_heading.text}</h1>
                    <h5>{data.left_text.text}</h5>
                  </div>
                </div>
                <div className="right-column text-end">
                  <div className="container d-flex flex-wrap">
                    <h1>{data.right_heading.text}</h1>
                    <h5>{data.right_text.text}</h5>
                  </div>
                </div>
              </div>
              <div className="second-row w-100 text-center">
                <h5 className="w-100 center-text me-1">{data.center_text.text}</h5>
              </div>
            </div>

            {content}
          </Layout>
        </>
    );
  }

}

export default IndexPage;

export const query = graphql`
{
  homepage: allPrismicHomepage {
    edges {
      node {
        id
        data {
          body {
            ... on PrismicHomepageDataBodyShapeImages {
              id
              primary {
                circle_image_bottom {
                  alt
                  url
                }
                circle_image_top {
                  alt
                  url
                }
                square_image_bottom {
                  alt
                  url
                }
                text {
                  text
                }
                triangle_image_top {
                  url
                  alt
                }
              }
              slice_type
            }
            ... on PrismicHomepageDataBodyLogos {
              id
              primary {
                logos_image {
                  alt
                  url
                }
                slice_heading {
                  text
                }
              }
              slice_type
            }
            ... on PrismicHomepageDataBodyCaseStudies {
              id
              primary {
                button_text {
                  text
                }
                background_image_1 {
                  alt
                  url
                }
                background_image_2 {
                  alt
                  url
                }
                slice_heading {
                  text
                }
              }
              slice_type
            }
            ... on PrismicHomepageDataBodyTestimonials {
              id
              primary {
                slice_heading {
                  richText
                }
              }
              items {
                company {
                  text
                }
                person {
                  text
                }
                text {
                  richText
                }
              }
              slice_type
            }
          }
          center_text {
            text
          }
          header_style
          left_heading {
            text
          }
          left_text {
            text
          }
          meta_description {
            text
          }
          meta_title {
            text
          }
          page_title {
            text
          }
          right_heading {
            text
          }
          right_text {
            text
          }
        }
      }
    }
  },
  casestudies: allPrismicCaseStudy(sort: {fields: data___case_study_order, order: ASC}) {
    edges {
      node {
        data {
          project_title {
            text
          }
          featured_image {
            alt
            url
          }
          client_name {
            text
          }
          categories {
            category
          }
          case_study_title {
            text
          }
        }
        uid
        url
      }
    }
  }

  header: prismicNavigation {
    ...FragmentHeader
  }

  footer: prismicFooter {
    ...FragmentFooter
  }
}
`