import React from 'react'
import '../../stylesheets/layout/opening.scss';
import { BlueCircle, PinkCircle, Triangle, Square } from './svg';

class OpeningAnimation extends React.Component {

  setSession = () => {
    sessionStorage.setItem('showOpening', false);
    return;
  }

  onAnimationEnd = () => {
    this.setSession();
  };

  render() {

    const { showOpeningAnimation } = this.props;

    return (
      <>
        {showOpeningAnimation ?
          <div className="opening-animation" onAnimationEnd={this.onAnimationEnd}>
            <div className="black-background"></div>
            <div className="inner-shapes d-flex flex-wrap justify-content-center align-items-center">
              <div className="blue-circle-container"><BlueCircle /></div>
              <div className="square-container"><Square /></div>
              <div className="triangle-container"><Triangle /></div>
              <div className="pink-circle-container"><PinkCircle /></div>
            </div>

            <div className="columns">
              {this.props.children}
            </div>
          </div> : this.props.children}
      </>
    )
  }
}

export default OpeningAnimation